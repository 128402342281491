import type { TranslationFunctions } from '@packages/i18n/src/i18n-types'
import { z } from 'zod'

export const forbiddenTenantAliases = [
  'admin',
  'api',
  'assets',
  'auth',
  'www',
  'dev',
  'prod',
  'staging',
  'ln',
  'sl',
  'ap',
  'ci',
  'cypress',
  'test',
  'none',
  'undefined',
  'null',
  'scim',
]

export const tenantAlias = (LL?: TranslationFunctions) =>
  z
    .string()
    .min(2, LL?.validation.tooShort(2))
    .max(100, LL?.validation.tooLong(100))
    // only all?ow lowercase letters, numbers and dashes
    .regex(/^[a-z\d-]+$/u, {
      message: LL?.validation.onlyLowercaseLettersNumbersAndDashes(),
    })
    .superRefine((value, context) => {
      const forbiddenPrefix = forbiddenTenantAliases.find((alias) =>
        value.startsWith(alias)
      )
      if (forbiddenPrefix)
        context.addIssue({
          code: z.ZodIssueCode.custom,
          message: LL?.validation.valueCantStartWith(forbiddenPrefix),
        })
    })

export const tenantSubdomain = tenantAlias

export const tenantDisplayName = (LL?: TranslationFunctions) =>
  z
    .string()
    .min(5, LL?.validation.tooShort(5))
    .max(100, LL?.validation.tooLong(100))

export const tenantInitials = (LL?: TranslationFunctions) =>
  z
    .string()
    .max(3, { message: LL?.validation.tooLong(3) })
    .optional()

export const checkinFrequencyShape = z.enum([
  'biweekly',
  'off',
  'weekly',
  'monthly',
  'inherited',
])

export type CheckinFrequency = z.infer<typeof checkinFrequencyShape>

export const CheckinReminderSettingsShape = z.object({
  dayOfWeek: z.number().min(0).max(6),
  defaultCycle: checkinFrequencyShape,
  enabled: z.boolean(),
  useEndOfMonth: z.boolean().optional(),
  utcHour: z.number().min(0).max(23),
})

export type CheckinReminderSettings = z.infer<
  typeof CheckinReminderSettingsShape
>

export const iterationSettingsShape = z
  .object({
    cycleStart: z.object({
      day: z.number().optional(),
      month: z.number().optional(),
    }),
    monthIterationName: z.string().optional(),
    quarterIterationName: z.string().optional(),
    yearIterationName: z.string().optional(),
  })
  .optional()

export const MethodologySettingsShape = z.object({
  checkinDueFrequency: checkinFrequencyShape.optional(),
  iterations: iterationSettingsShape.optional(),
})

export const TenantSettingsShape = z.object({
  checkinReminder: CheckinReminderSettingsShape.optional(),
  methodology: MethodologySettingsShape.optional(),
})

export type TenantSettings = z.infer<typeof TenantSettingsShape>

export const mutateTeamShape = (LL?: TranslationFunctions) =>
  z.object({
    color: z
      .string()
      .regex(/^$|^#[\da-f]{6}$/iu)
      .optional()
      .nullable(),
    description: z
      .string()
      .max(300, {
        message: LL?.validation.tooLong(300),
      })
      .optional(),
    displayName: z
      .string()
      .trim()
      .min(1, {
        message: LL?.validation.tooShort(1),
      })
      .max(100, {
        message: LL?.validation.tooLong(100),
      }),
    inheritSubTeamMembers: z.boolean().optional(),
    members: z.array(
      z.object({
        role: z.enum(['member', 'editor']).optional().nullable(),
        userId: z.string(),
      })
    ),
    parentTeamId: z.string().optional(),
    teamLeaderIds: z.array(z.string().nullable().optional()),
  })

export const teamShape = mutateTeamShape().extend({
  teamId: z.string(),
  tenantId: z.string(),
})

export type Team = z.infer<typeof teamShape>
