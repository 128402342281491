import { useAuth } from '@hooks'
import { generateColors } from '@mantine/colors-generator'
import type { MantineColorsTuple } from '@mantine/core'
import type { ApiReturnTypes } from '@types'
import { trpc } from '@utils/trpc'
import { useMemo } from 'react'
import { usePermissions } from './usePermissions'

type Memberstatus =
  | 'leader'
  | ApiReturnTypes['organization']['listTeams']['teams'][number]['members'][number]['role']

const getSubTeams = ({
  teams,
  teamId,
}: {
  teamId: string
  teams: ApiReturnTypes['organization']['listTeams']['teams']
}): string[] => {
  const team = teams.find((findTeam) => findTeam.teamId === teamId)
  if (!team?.inheritSubTeamMembers) return [teamId]

  const subTeams = teams.filter((findTeam) => findTeam.parentTeamId === teamId)

  if (subTeams.length === 0) {
    return [teamId]
  }

  return subTeams.flatMap((subTeam) =>
    getSubTeams({ teamId: subTeam.teamId, teams })
  )
}

const getMemberStatus = ({
  userId,
  teamId,
  teams,
}: {
  teamId: string
  teams: ApiReturnTypes['organization']['listTeams']['teams']
  userId: string
}) => {
  const team = teams.find((findTeam) => findTeam.teamId === teamId)

  if (team?.teamLeaderIds.includes(userId)) {
    return 'leader'
  }

  const foundMemberShip = team?.members.find(
    (member) => member.userId === userId
  )
  if (foundMemberShip) {
    return foundMemberShip.role ?? 'member'
  }

  return null
}

const checkIfUserIsInherited = ({
  teams,
  userId,
  teamId,
}: {
  teamId: string
  teams: ApiReturnTypes['organization']['listTeams']['teams']
  userId: string
}) => {
  const inheritedFrom: Array<{
    memberStatus: Memberstatus
    teamId: string
  }> = []

  const subTeams = [...new Set(getSubTeams({ teamId, teams }))].filter(
    (id) => id !== teamId
  )

  for (const subTeamId of subTeams) {
    const memberStatus = getMemberStatus({ teamId: subTeamId, teams, userId })
    if (memberStatus) {
      inheritedFrom.push({
        memberStatus,
        teamId: subTeamId,
      })
    }
  }

  return inheritedFrom
}

export const getTeamMembershipsForUser = (
  teams: ApiReturnTypes['organization']['listTeams']['teams'],
  userId: string
) => {
  const teamMemberships: Array<
    {
      colorScheme: MantineColorsTuple | undefined
      inheritedFrom: Array<{
        memberStatus: Memberstatus
        teamId: string
        teamName: string
      }>
      memberStatus: Memberstatus
    } & ApiReturnTypes['organization']['listTeams']['teams'][number]
  > = []

  for (const team of teams) {
    const colorScheme = team.color ? generateColors(team.color) : undefined
    const memberStatus = getMemberStatus({ teamId: team.teamId, teams, userId })

    const newMembership: (typeof teamMemberships)[number] = {
      ...team,
      colorScheme,
      inheritedFrom: [],
      memberStatus,
    }

    if (memberStatus) {
      teamMemberships.push(newMembership)
    }

    const inheritedMemberships = checkIfUserIsInherited({
      teamId: team.teamId,
      teams,
      userId,
    })

    for (const inheritedMembership of inheritedMemberships) {
      const inheritedTeam = teams.find(
        (findTeam) => findTeam.teamId === inheritedMembership.teamId
      )
      if (!inheritedTeam) continue

      const inheritanceInfo = {
        memberStatus: inheritedMembership.memberStatus,
        teamId: inheritedMembership.teamId,
        teamName: inheritedTeam.displayName,
      }

      const existingMembership = teamMemberships.find(
        (findTeam) => findTeam.teamId === team.teamId
      )
      if (!existingMembership) teamMemberships.push(newMembership)

      const membership = existingMembership ?? newMembership

      if (
        membership.inheritedFrom.every(
          (findInheritance) => findInheritance.teamId !== team.teamId
        )
      )
        membership.inheritedFrom.push(inheritanceInfo)
    }
  }

  return teamMemberships
}

export const useTeamMemberships = (userId: string | undefined) => {
  const { authorized } = usePermissions(['Members:Read'])

  const { data, isPending } = trpc.organization.listTeams.useQuery(undefined, {
    enabled: authorized,
    staleTime: 3_600 * 1_000,
  })

  const { userId: currentUserId } = useAuth()
  const userIdToCheck = userId ?? currentUserId

  const memberships = useMemo(() => {
    if (!userIdToCheck) {
      return []
    }

    return getTeamMembershipsForUser(data?.teams ?? [], userIdToCheck)
  }, [data?.teams, userIdToCheck])

  return {
    authorized,
    isPending,
    memberships,
  }
}
