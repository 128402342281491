import type { SVGIcon } from '@icons'
import type { TablerIcon } from '@types'
import { cn } from '@utils'
import type { SVGProps } from 'react'

const RingBg = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill='none'
    height={480}
    width={480}
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <svg
      fill='none'
      height='480'
      viewBox='0 0 480 480'
      width='480'
      xmlns='http://www.w3.org/2000/svg'
    >
      <title>Ring Background</title>
      <g>
        <path
          d='M240 288C266.51 288 288 266.51 288 240C288 213.49 266.51 192 240 192C213.49 192 192 213.49 192 240C192 266.51 213.49 288 240 288Z'
          stroke='#EAECF0'
        />
        <path
          className='opacity-[85]'
          d='M240 320C284.183 320 320 284.183 320 240C320 195.817 284.183 160 240 160C195.817 160 160 195.817 160 240C160 284.183 195.817 320 240 320Z'
          stroke='#EAECF0'
        />
        <path
          className='opacity-70'
          d='M240 352C301.856 352 352 301.856 352 240C352 178.144 301.856 128 240 128C178.144 128 128 178.144 128 240C128 301.856 178.144 352 240 352Z'
          stroke='#EAECF0'
        />
        <path
          className='opacity-[55]'
          d='M240 384C319.529 384 384 319.529 384 240C384 160.471 319.529 96 240 96C160.471 96 96 160.471 96 240C96 319.529 160.471 384 240 384Z'
          stroke='#EAECF0'
        />
        <path
          className='opacity-40'
          d='M240 416C337.202 416 416 337.202 416 240C416 142.798 337.202 64 240 64C142.798 64 64 142.798 64 240C64 337.202 142.798 416 240 416Z'
          stroke='#EAECF0'
        />
        <path
          className='opacity-25'
          d='M240 448C354.875 448 448 354.875 448 240C448 125.125 354.875 32 240 32C125.125 32 32 125.125 32 240C32 354.875 125.125 448 240 448Z'
          stroke='#EAECF0'
        />
        <path
          className='opacity-10'
          d='M240 480C372.548 480 480 372.548 480 240C480 107.452 372.548 0 240 0C107.452 0 0 107.452 0 240C0 372.548 107.452 480 240 480Z'
          stroke='#EAECF0'
        />
      </g>
    </svg>
  </svg>
)

type IconRingWrapperProps = {
  readonly icon: SVGIcon | TablerIcon
  readonly iconProps?: SVGProps<SVGSVGElement>
  readonly padding?: `${number}px` | `${number}rem`
  readonly ringBgClassName?: string
  readonly size: `${number}px` | `${number}rem`
  readonly variant?: 'default' | 'danger' | 'warning'
}

const IconRingWrapper = ({
  icon,
  size,
  ringBgClassName,
  iconProps,
  padding,
  variant,
}: IconRingWrapperProps) => {
  const Icon = icon

  const iconColors = {
    danger: 'text-red-500',
    default: 'text-gray-700',
    warning: 'text-warning-500',
  }

  const iconColor = iconColors[variant ?? 'default']

  return (
    <div className='relative'>
      <div
        className='relative grid place-items-center rounded-xl border border-solid border-gray-200 bg-white shadow-xs'
        style={{
          height: size,
          padding: padding ?? '0.75rem',
          width: size,
        }}
      >
        <div className='absolute -z-10'>
          <RingBg
            className={cn(
              'absolute -translate-x-1/2 -translate-y-1/2',
              ringBgClassName
            )}
          />
        </div>
        <Icon className={cn(iconColor)} {...iconProps} />
      </div>
    </div>
  )
}

export default IconRingWrapper
