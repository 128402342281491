import type { MantineThemeColors } from '@mantine/core'
import { getPeriodForAoa, isActiveAoa, isExpiredAoa } from '@packages/aoa-utils'
import type { TranslationFunctions } from '@packages/i18n/src/i18n-types'
import {
  AoaApprovableStatus,
  type AoaInfo,
  type AoaItem,
  AoaProgressStatus,
  type Iteration,
} from '@packages/types'
import type { ApiReturnTypes } from '@types'
import _ from 'lodash'

/**
 * An item is relevant for the current AOA
 * if it has a child in this AOA or if it is in this AOA itself.
 *
 * In draft mode, also items that are in the parent AOA are relevant.
 */
export const filterRelevant = <
  T extends { aoaId: string; itemId: string; parentItemIds: string[] },
>(
  items: Readonly<T[]>,
  aoaInfo: AoaInfo
) =>
  items.filter(
    (item) =>
      item.aoaId === aoaInfo.aoaId ||
      items.some(
        (otherItem) =>
          otherItem.aoaId === aoaInfo.aoaId &&
          otherItem.parentItemIds.includes(item.itemId)
      ) ||
      (aoaInfo.status === AoaApprovableStatus.Draft &&
        (items.some(
          (otherItem) =>
            otherItem.aoaId === aoaInfo.parentAoaId &&
            otherItem.parentItemIds.includes(item.itemId)
        ) ||
          item.aoaId === aoaInfo.parentAoaId))
  )

export const sortAoaItemsBySortIndex = <
  T extends { itemId: string; sortIndex?: string },
>(
  items: Readonly<T[]>
) => {
  const sortedItems = [...items]

  return sortedItems.sort((itemA, itemB) =>
    // sort by sortIndex or itemId respectively
    (itemA.sortIndex ?? itemA.itemId).localeCompare(
      itemB.sortIndex ?? itemB.itemId
    )
  )
}

export const sortAoaByPeriodEnd = <
  T extends {
    iteration?: Iteration
  },
>({
  aoas,
  iterationCycles,
}: {
  aoas: T[]
  iterationCycles: ApiReturnTypes['organization']['listIterationCycles']['cycles']
}): T[] => {
  const latestAoaFromSeries = aoas?.sort((aoaA, aoaB) => {
    const periodA = getPeriodForAoa({
      cycles: iterationCycles,
      iteration: aoaA.iteration,
    })
    const periodB = getPeriodForAoa({
      cycles: iterationCycles,
      iteration: aoaB.iteration,
    })

    // periodA and periodB can be null if no iteration is set
    // return the latest first
    if (!periodA) return 1
    if (!periodB) return -1
    // use to sort by date, most recent end date first
    return periodB.end.localeCompare(periodA.end)
  })

  return latestAoaFromSeries
}

export const sortAoaItemsByPriority = <
  T extends {
    periodEnd?: string
    tags?: string[]
  },
>(
  items: Readonly<T[]>
) => {
  const sortedItems = [...items]

  return sortedItems.sort(
    (itemA, itemB) =>
      // sort by has tag gs:highPriority
      (itemB.tags?.includes('gs:highPriority') ? 1 : 0) -
        (itemA.tags?.includes('gs:highPriority') ? 1 : 0) ||
      // then by periodEnd, ascending, undefined last
      (itemA.periodEnd === undefined ? 1 : 0) -
        (itemB.periodEnd === undefined ? 1 : 0) ||
      new Date(itemA.periodEnd ?? 0).valueOf() -
        new Date(itemB.periodEnd ?? 0).valueOf()
  )
}

export const percentageToColor = (percentage: number) =>
  percentage >= 0.8 ? 'green' : percentage >= 0.5 ? 'orange' : 'red'

const progressColors = {
  [AoaProgressStatus.Delayed]: 'yellow',
  [AoaProgressStatus.OffTrack]: 'orange',
  [AoaProgressStatus.OnTrack]: 'lime',
  [AoaProgressStatus.Cancelled]: 'gray',
  [AoaProgressStatus.Blocked]: 'red',
  [AoaProgressStatus.Done]: 'green',
} satisfies Record<AoaProgressStatus, keyof MantineThemeColors>

export const progressToColor = (
  progress: AoaProgressStatus
): keyof MantineThemeColors => progressColors[progress] ?? 'gray'

export const confidenceToLabel = (
  confidence: number | undefined,
  LL: TranslationFunctions
) => {
  if (confidence === undefined) return null
  if (confidence >= 0.8) return LL.common.status.high()
  if (confidence >= 0.5) return LL.common.status.medium()
  return LL.common.status.low()
}

export const isAoaTextItem = (item: Readonly<AoaItem>) =>
  item.itemType === 'text'

export const isAoaIntentItem = (item: Readonly<AoaItem>) =>
  item.itemType === 'intent'

export const isAoaOutcomeItem = (item: Readonly<AoaItem>) =>
  item.itemType === 'outcome'

/**
 * Check if an item is concluded.
 * An item is concluded if it is done or cancelled.
 */
export const outcomeIsConcluded = (
  item: Pick<
    AoaItem,
    'actualProgress' | 'actualQuantifier' | 'plannedQuantifier' | 'type'
  >
) =>
  item.actualProgress === AoaProgressStatus.Done ||
  item.actualProgress === AoaProgressStatus.Cancelled ||
  // outcome is yes
  (item.type === 0 && item.actualQuantifier === 1) ||
  // or result is (over-)achieved
  (item.type === 1 &&
    item.actualQuantifier !== undefined &&
    item.plannedQuantifier !== undefined &&
    item.actualQuantifier >= item.plannedQuantifier)

export const isRootAoa = (aoaInfo: { level: number }) => aoaInfo.level === 0

export const findRelevantRootAoa = <
  T extends {
    aoaId: string
    iteration?: AoaInfo['iteration']
    level: number
    status: AoaApprovableStatus
  },
>(
  aoas: Readonly<T[]>,
  cycles:
    | ApiReturnTypes['organization']['listIterationCycles']['cycles']
    | undefined
) => {
  // filter root AOAs
  const rootAoas = aoas?.filter(isRootAoa)

  if (rootAoas.length === 1) return rootAoas[0]
  if (rootAoas.length === 0) return undefined

  const firstActiveAoa = rootAoas.find((aoa) =>
    isActiveAoa(aoa, cycles, rootAoas ?? [])
  )

  const notExpiredRootAoa = rootAoas.find(
    (aoa) => !isExpiredAoa({ cycles, iteration: aoa.iteration })
  )

  // first non-expired root AOA or last expired root AOA
  return firstActiveAoa ?? notExpiredRootAoa ?? rootAoas.at(-1)
}

export const showRelevantAoaForSeries = <
  T extends {
    aoaId: string
    iteration?: AoaInfo['iteration']
    level: number
    seriesId?: string | undefined
    status: AoaApprovableStatus
  },
>(
  aoas: Readonly<T[]>,
  cycles:
    | ApiReturnTypes['organization']['listIterationCycles']['cycles']
    | undefined,
  options?: {
    showActiveAoasOnly?: boolean
  }
): T[] => {
  const aoaBySeries = _(aoas).groupBy('seriesId').value()

  const result: T[] = []

  for (const aoaList of Object.values(aoaBySeries)) {
    if (!aoaList) continue

    const firstNotExpiredAoa = aoaList.find(
      (aoa) =>
        !isExpiredAoa({ cycles, iteration: aoa.iteration }) && aoa.seriesId
    )

    const firstActiveAoa = aoaList.find(
      (aoa) => isActiveAoa(aoa, cycles, aoaList) && aoa.seriesId
    )

    if (firstActiveAoa) result.push(firstActiveAoa)
    else if (!options?.showActiveAoasOnly && firstNotExpiredAoa)
      result.push(firstNotExpiredAoa)
    else if (!options?.showActiveAoasOnly) {
      const lastExpiredAoa = aoaList.at(-1)
      if (lastExpiredAoa) result.push(lastExpiredAoa)
    }
  }

  for (const aoa of aoas) {
    if (!aoa.seriesId) result.push(aoa)
  }

  return result
}
