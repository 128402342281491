import { ActionIcon, type ActionIconProps } from '@mantine/core'
import {
  IconCheck,
  IconDots,
  IconHistory,
  IconPencil,
  IconStatusChange,
  IconTrash,
  IconX,
} from '@tabler/icons-react'
import type { TablerIcon, TablerIconProps } from '@types'
import { type MouseEventHandler, forwardRef } from 'react'

const commonActionIconProps = {
  variant: 'light',
} satisfies ActionIconProps

const defaultIconSize = '1rem'

export type ButtonProps = ActionIconProps &
  React.ComponentPropsWithoutRef<'button'> & {
    readonly iconSize?: TablerIconProps['size']
    readonly onClick?: MouseEventHandler<HTMLButtonElement>
  }

const createActionButtonComponent = (
  name: string,
  Icon: TablerIcon,
  defaultActionIconProps?: ActionIconProps &
    React.ComponentPropsWithoutRef<'button'>
) => {
  const Button = forwardRef<HTMLButtonElement, ButtonProps>(
    ({ iconSize = defaultIconSize, ...userActionIconProps }, ref) => (
      <ActionIcon
        ref={ref}
        {...commonActionIconProps}
        {...defaultActionIconProps}
        {...userActionIconProps}
      >
        <Icon size={iconSize} />
      </ActionIcon>
    )
  )
  Button.displayName = name
  return Button
}

export const MenuButton = createActionButtonComponent('MenuButton', IconDots, {
  title: 'Open Menu',
  variant: 'subtle',
})

export const SaveButton = createActionButtonComponent('SaveButton', IconCheck, {
  color: 'green',
  title: 'Save',
})

export const CancelButton = createActionButtonComponent('CancelButton', IconX, {
  title: 'Cancel',
})

export const EditButton = createActionButtonComponent(
  'EditButton',
  IconPencil,
  {
    title: 'Edit',
    variant: 'subtle',
  }
)

export const DeleteButton = createActionButtonComponent(
  'DeleteButton',
  IconTrash,
  {
    color: 'red',
    title: 'Delete',
  }
)

export const CheckinButton = createActionButtonComponent(
  'CheckinButton',
  IconStatusChange,
  {
    color: 'primary',
    title: 'Check In',
  }
)

export const HistoryButton = createActionButtonComponent(
  'HistoryButton',
  IconHistory,
  {
    title: 'History',
  }
)
