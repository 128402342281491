import {
  getLoaderLocalStorageKey,
  getLoaderLocalStorageValue,
} from '@utils/localstorage-utils'
import { useCallback, useEffect, useMemo } from 'react'
import { useFetcher } from 'react-router-dom'
/**
 * Util to prefetch data for a given pathname
 */
export const usePrefetch = (pathname?: string) => {
  const fetcher = useFetcher()

  const key = useMemo(
    () => getLoaderLocalStorageKey(pathname ?? ''),
    [pathname]
  )

  const getValue = useCallback(
    () => getLoaderLocalStorageValue(pathname ?? ''),
    [pathname]
  )

  const setValue = useCallback(
    (value: unknown) => {
      try {
        localStorage.setItem(
          key,
          JSON.stringify({
            timestamp: Date.now(),
            value,
          })
        )
      } catch {
        /* empty */
      }
    },
    [key]
  )

  const handlePrefetch = useCallback(() => {
    if (
      fetcher.state === 'idle' &&
      // fetcher.data === undefined &&
      pathname &&
      getValue() === undefined
    ) {
      localStorage.setItem(`${key}-prefetching`, 'true')
      fetcher.data = undefined
      fetcher.load(pathname)
    }
  }, [fetcher, getValue, key, pathname])

  const clearPrefetchFlag = useCallback(() => {
    localStorage.removeItem(`${key}-prefetching`)
  }, [key])

  useEffect(() => {
    if (fetcher.data) {
      setValue(fetcher.data)
    }
  }, [fetcher, setValue])

  return { clearPrefetchFlag, handlePrefetch }
}
