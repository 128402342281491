import { Avatar, type AvatarProps, Skeleton, rem } from '@mantine/core'
import type { ReactNode } from 'react'

export type CommonAvatarProps = {
  readonly avatarProps?: Omit<AvatarProps, 'children' | 'src'>
  readonly displayName?: string
  readonly fallback?: ReactNode
  readonly initials?: string | null
  readonly isLoading?: boolean
  readonly url?: string
}

// from node_modules/@mantine/core/esm/Avatar/Avatar.js
const avatarSize: Record<string, string> = {
  lg: rem(56),
  md: rem(38),
  sm: rem(26),
  xl: rem(84),
  xs: rem(16),
}

const CommonAvatar = (props: CommonAvatarProps) => {
  const initials: string | null = props.url ? null : props.initials ?? null

  const size = props.avatarProps?.size ?? 'md'

  if (props.isLoading) {
    const width =
      size && typeof size === 'string' && size in avatarSize
        ? avatarSize[size]
        : size

    return (
      <Skeleton
        c='gray'
        className='no-delay'
        height={width}
        radius={props.avatarProps?.radius ?? 500}
        width={width}
      />
    )
  }

  return (
    <Avatar
      alt={props.displayName ?? props.initials ?? undefined}
      radius={500}
      {...props.avatarProps}
      size={size}
      src={props.url}
      title={props.displayName ?? props.initials ?? undefined}
    >
      {initials ?? props.fallback ?? null}
    </Avatar>
  )
}

export default CommonAvatar
