import { AoaApprovableStatus } from '@packages/types'
import type { PossibleIteration } from './new-aoa-iteration'

type TimeRangeDate = `${number}-${string}-${string}`

type TimeRange = {
  end: TimeRangeDate
  start: TimeRangeDate
}

type CycleStart = {
  day: number
  month: number
}

type Iteration = {
  iterationIntervalType: 'MONTH' | 'QUARTER' | 'YEAR'
  iterationSequenceNumber: number
  year: number
}

type IterationWithId = Iteration & {
  iterationCycleId: string
}

type MinimalCycle = {
  cycleStart: {
    day: number
    month: number
  }
  earlyTerminationDate?: string
  terminated?: boolean
}

const getTimeRangeDate = (date: Date): TimeRangeDate => {
  const month =
    date.getMonth() > 8 ? date.getMonth() + 1 : `0${date.getMonth() + 1}`
  const day = date.getDate() > 9 ? date.getDate() : `0${date.getDate()}`

  return `${date.getFullYear()}-${month}-${day}`
}

const DAY_IN_MS = 24 * 60 * 60 * 1_000

/**
 * Get the end of a period for a given start date and period length minus one day
 * If the date would not exist (example 31. January + 1 month) the last valid date is returned
 * @example getPeriodEnd(2021, { month: 1, day: 31 }, 1) // 2021-02-28
 * @example getPeriodEnd(2021, { month: 1, day: 31 }, 2) // 2021-03-30
 * @example getPeriodEnd(2021, { month: 1, day: 1 }, 3) // 2021-01-31
 */
export const getPeriodEnd = (
  startDate: Date,
  periodLengthMonths: number,
  startDay: number
): TimeRangeDate => {
  const endDay = startDay - 1

  const endDate = new Date(startDate)
  endDate.setMonth(endDate.getMonth() + periodLengthMonths)
  endDate.setDate(endDate.getDate() - 1)

  if (
    endDate.getMonth() - startDate.getMonth() !== periodLengthMonths &&
    endDate.getDate() < endDay
  ) {
    endDate.setMonth(endDate.getMonth() - 1)
    // set endDay if the month has that many days, otherwise use the last day of the month - 1
    const endDayOfMonth = new Date(
      endDate.getFullYear(),
      endDate.getMonth() + 1,
      0
    ).getDate()
    endDate.setDate(endDayOfMonth < endDay ? endDayOfMonth - 1 : endDay - 1)
  } else if (endDate.getDate() < endDay) {
    const endDayOfMonth = new Date(
      endDate.getFullYear(),
      endDate.getMonth() + 1,
      0
    ).getDate()
    endDate.setDate(endDayOfMonth < endDay ? endDayOfMonth - 1 : endDay)
  } else if (endDay >= 28 && endDate.getDate() >= 28) {
    // if the endday has no follow up day in the month, redact one day
    if (
      new Date(endDate.getTime() + DAY_IN_MS).getMonth() !== endDate.getMonth()
    ) {
      endDate.setDate(endDate.getDate() - 1)
    }
  }

  return getTimeRangeDate(endDate)
}

/**
 * A year must be split up into 4 quarters and 12 months.
 * There shall be no overlap or gap between the quarters and months.
 * If the iteration cycle is terminated, the last period shall be
 * the period in which the iteration cycle was terminated. The end date
 * can't be after the termination date.
 * @param input - The input to validate
 * @param input.cycleStart - The start of the fiscal year
 * @param input.year - The year to get the iterations for
 * @param input.earlyTerminationDate - The date the iteration cycle was terminated
 * @param input.terminated - If the iteration cycle was terminated
 * @returns an object containing the start and end dates for the year, quarters and months
 */
export const getIterationsForYearWithCycleStart = ({
  cycleStart,
  year,
  earlyTerminationDate,
  terminated,
}: {
  cycleStart: CycleStart
  earlyTerminationDate?: string
  terminated?: boolean
  year: number
}) => {
  const yearStart = new Date(year, cycleStart.month - 1, cycleStart.day)
  const regularYearEnd = new Date(
    year + 1,
    cycleStart.month - 1,
    cycleStart.day - 1
  )
  const yearEnd =
    terminated &&
    earlyTerminationDate &&
    new Date(earlyTerminationDate).getTime() < regularYearEnd.getTime()
      ? new Date(earlyTerminationDate)
      : regularYearEnd
  const nextYearStart = new Date(yearEnd.getTime() + DAY_IN_MS)

  if (
    terminated &&
    earlyTerminationDate &&
    new Date(yearStart).getTime() > new Date(earlyTerminationDate).getTime()
  ) {
    return {
      monthStarts: [] as TimeRange[],
      quarters: [] as TimeRange[],
      year: null,
    }
  }

  const quarters: TimeRange[] = []
  const monthStarts: TimeRange[] = []

  // Get the start and end dates for each quarter
  for (let index = 0; index < 4; index++) {
    const lastQuaterStart = quarters[index - 1]?.end
    const quarterStart = new Date(
      lastQuaterStart
        ? new Date(lastQuaterStart).getTime() + DAY_IN_MS
        : yearStart.getTime()
    )
    const quarterEnd = getPeriodEnd(quarterStart, 3, cycleStart.day)

    if (new Date(quarterEnd).getTime() > yearEnd.getTime()) {
      if (new Date(quarterStart).getTime() >= new Date(yearEnd).getTime()) {
        break
      }

      quarters.push({
        end: getTimeRangeDate(yearEnd),
        start: getTimeRangeDate(quarterStart),
      })
      break
    }

    // if (new Date(quarterStart).getTime() >= new Date(yearEnd).getTime()) {
    //   break
    // }

    quarters.push({
      end: quarterEnd,
      start: getTimeRangeDate(quarterStart),
    })
  }

  // Get the start and end dates for each month
  for (let index = 0; index < 12; index++) {
    const lastMonthStart = monthStarts[index - 1]?.end
    const monthStart = new Date(
      lastMonthStart
        ? new Date(lastMonthStart).getTime() + DAY_IN_MS
        : yearStart.getTime()
    )
    const monthEnd = getPeriodEnd(monthStart, 1, cycleStart.day)

    if (new Date(monthEnd).getTime() > yearEnd.getTime()) {
      if (new Date(monthStart).getTime() > new Date(yearEnd).getTime()) {
        break
      }

      monthStarts.push({
        end: getTimeRangeDate(yearEnd),
        start: getTimeRangeDate(monthStart),
      })
      break
    }

    monthStarts.push({
      end: monthEnd,
      start: getTimeRangeDate(monthStart),
    })
  }

  return {
    monthStarts,
    quarters,
    year: {
      end: getTimeRangeDate(yearEnd),
      nextStart: getTimeRangeDate(nextYearStart),
      start: getTimeRangeDate(yearStart),
    },
  }
}

/**
 * Get the start and end date for the current iteration cycle
 */
export const terminateIterationCycle = ({
  newCycleStart,
  currentDate,
}: {
  currentDate: Date
  newCycleStart: CycleStart
}) => {
  const currentYear = currentDate.getFullYear()

  const newIterationStartYear =
    new Date(
      currentYear,
      newCycleStart.month - 1,
      newCycleStart.day
    ).getTime() < currentDate.getTime()
      ? currentYear + 1
      : currentYear

  const newIterationStart = new Date(
    newIterationStartYear,
    newCycleStart.month - 1,
    newCycleStart.day
  )
  const oldIterationEnd = new Date(newIterationStart.getTime() - DAY_IN_MS)

  return {
    newIterationStart: getTimeRangeDate(newIterationStart),
    oldIterationEnd: getTimeRangeDate(oldIterationEnd),
  }
}

/**
 * If the current date is in last years iteration cycle, return the last years iteration cycle
 */
export const getCurrentCycleYear = ({
  cycleStart,
  currentDate,
}: {
  currentDate: Date
  cycleStart: CycleStart
}) => {
  const currentYear = currentDate.getFullYear()

  const cycleStartThisYear = new Date(
    currentYear,
    cycleStart.month - 1,
    cycleStart.day
  )

  return cycleStartThisYear.getTime() > currentDate.getTime()
    ? currentYear + 1
    : currentYear
}

export const getPeriodForIteration = ({
  cycle,
  iteration,
}: {
  cycle: MinimalCycle
  iteration: Iteration
}):
  | {
      end: TimeRangeDate
      start: TimeRangeDate
    }
  | undefined => {
  const { cycleStart, earlyTerminationDate, terminated } = cycle
  const { monthStarts, quarters, year } = getIterationsForYearWithCycleStart({
    cycleStart,
    earlyTerminationDate,
    terminated,
    year: iteration.year,
  })

  let interval:
    | {
        end: TimeRangeDate
        start: TimeRangeDate
      }
    | undefined

  if (iteration.iterationIntervalType === 'MONTH') {
    interval = monthStarts[iteration.iterationSequenceNumber]
  }

  if (iteration.iterationIntervalType === 'QUARTER') {
    interval = quarters[iteration.iterationSequenceNumber]
  }

  if (iteration.iterationIntervalType === 'YEAR' && year) {
    interval = {
      end: year.end,
      start: year.start,
    }
  }

  if (
    terminated &&
    earlyTerminationDate &&
    interval &&
    new Date(interval.end).getTime() > new Date(earlyTerminationDate).getTime()
  ) {
    interval = {
      ...interval,
      end: getTimeRangeDate(new Date(earlyTerminationDate)),
    }
  }

  return interval
}

export const getCurrentIteration = <
  TCycle extends MinimalCycle & { created: string },
>({
  cycles,
}: {
  cycles: TCycle[]
}) => {
  return cycles
    .filter((cycle) => !cycle.terminated)
    .sort((cycleA, cycleB) => {
      return cycleA.created.localeCompare(cycleB.created)
    })[0]
}

export const getNextPeriod = ({
  iteration,
  cycle,
}: {
  cycle: MinimalCycle
  earlyTerminationDate?: string
  iteration: Iteration
  terminated?: boolean
}): { nextIteration: Iteration; period: TimeRange } | null => {
  let nextIteration: Iteration | null = null
  if (iteration.iterationIntervalType === 'YEAR') {
    nextIteration = {
      iterationIntervalType: 'YEAR',
      iterationSequenceNumber: 0,
      year: iteration.year + 1,
    }
  } else if (iteration.iterationIntervalType === 'QUARTER') {
    nextIteration = {
      iterationIntervalType: 'QUARTER',
      iterationSequenceNumber:
        iteration.iterationSequenceNumber >= 3
          ? 0
          : iteration.iterationSequenceNumber + 1,
      year:
        iteration.iterationSequenceNumber >= 3
          ? iteration.year + 1
          : iteration.year,
    }
  } else if (iteration.iterationIntervalType === 'MONTH') {
    nextIteration = {
      iterationIntervalType: 'MONTH',
      iterationSequenceNumber:
        iteration.iterationSequenceNumber >= 11
          ? 0
          : iteration.iterationSequenceNumber + 1,
      year:
        iteration.iterationSequenceNumber >= 11
          ? iteration.year + 1
          : iteration.year,
    }
  }

  if (!nextIteration) return null

  const period = getPeriodForIteration({
    cycle,
    iteration: nextIteration,
  })

  if (!period) return null

  return {
    nextIteration,
    period,
  }
}

export const getNextIteration = ({
  currentIteration,
  cycles,
}: {
  currentIteration: Iteration & { iterationCycleId: string }
  cycles: Array<
    MinimalCycle & {
      created: string
      iterationCycleId: string
    }
  >
}): {
  nextIteration: Iteration & { iterationCycleId: string }
  period: TimeRange
} | null => {
  if (!currentIteration || !cycles) return null

  const currentIterationCycle = getCurrentIteration({ cycles })
  const selectedIterationCycle = cycles.find(
    (cycle) => cycle.iterationCycleId === currentIteration.iterationCycleId
  )

  if (!selectedIterationCycle || !currentIterationCycle) return null

  const currentNextAtStart =
    getNextPeriod({
      cycle: currentIterationCycle,
      iteration: { ...currentIteration, iterationSequenceNumber: -1 },
    }) ?? null

  const firstCurrentIteration:
    | {
        nextIteration: Iteration & { iterationCycleId: string }
        period: TimeRange
      }
    | undefined = currentNextAtStart
    ? {
        ...currentNextAtStart,
        nextIteration: {
          ...currentNextAtStart.nextIteration,
          iterationCycleId: currentIterationCycle.iterationCycleId,
        },
      }
    : undefined

  const nextPeriod = getNextPeriod({
    cycle: selectedIterationCycle,
    iteration: currentIteration,
  })

  if (
    selectedIterationCycle?.terminated &&
    selectedIterationCycle.earlyTerminationDate &&
    (!nextPeriod ||
      new Date(nextPeriod.period.end).getTime() >
        new Date(selectedIterationCycle.earlyTerminationDate).getTime())
  ) {
    if (
      nextPeriod &&
      new Date(nextPeriod.period.end).getTime() >
        new Date(selectedIterationCycle.earlyTerminationDate).getTime() &&
      new Date(nextPeriod.period.start).getTime() <
        new Date(selectedIterationCycle.earlyTerminationDate).getTime()
    ) {
      return {
        ...nextPeriod,
        nextIteration: {
          ...nextPeriod.nextIteration,
          iterationCycleId: selectedIterationCycle.iterationCycleId,
        },
        period: {
          ...nextPeriod.period,
          end: getTimeRangeDate(
            new Date(selectedIterationCycle.earlyTerminationDate)
          ),
        },
      }
    }

    return firstCurrentIteration ?? null
  }

  return nextPeriod
    ? {
        ...nextPeriod,
        nextIteration: {
          ...nextPeriod.nextIteration,
          iterationCycleId: selectedIterationCycle.iterationCycleId,
        },
      }
    : null
}

export const getPeriodForAoa = ({
  cycles,
  iteration,
}: {
  cycles?: Array<
    MinimalCycle & {
      created: string
      iterationCycleId: string
    }
  >
  iteration?: Iteration & { iterationCycleId: string }
}) => {
  const applicableCycle = cycles?.find(
    (cycle) => cycle.iterationCycleId === iteration?.iterationCycleId
  )

  if (!applicableCycle || !iteration) return undefined

  const period = getPeriodForIteration({
    cycle: applicableCycle,
    iteration,
  })

  return period
}

export const isExpiredAoa = ({
  cycles,
  iteration,
}: {
  cycles:
    | Array<
        MinimalCycle & {
          created: string
          iterationCycleId: string
        }
      >
    | undefined
  iteration?: Iteration & { iterationCycleId: string }
}) => {
  const period = getPeriodForAoa({ cycles, iteration })

  if (!period) return undefined

  return new Date(period.end).getTime() < Date.now()
}

/**
 * Aoas are active if they are approved and have already started
 * If the aoa is not approved or withdrawn it is not active
 */
export const isActiveAoa = (
  aoaInfo: Readonly<{
    iteration?: Iteration & { iterationCycleId: string }
    status: AoaApprovableStatus
  }>,
  cycles:
    | Array<
        MinimalCycle & {
          created: string
          iterationCycleId: string
        }
      >
    | undefined,
  series:
    | {
        iteration?: Iteration & { iterationCycleId: string }
        status: AoaApprovableStatus
      }[]
    | undefined
) => {
  // only approved AOAs are active
  if (aoaInfo.status !== AoaApprovableStatus.Approved) return false

  if (!aoaInfo.iteration) return false

  const period = getPeriodForAoa({ cycles, iteration: aoaInfo.iteration })

  if (!period) return undefined

  const sortedSeries = sortByIteration(series ?? [], cycles ?? [])
  const upcomingActiveAoas = sortedSeries.filter((upcomingAoa) => {
    const upcomingPeriod = getPeriodForAoa({
      cycles,
      iteration: upcomingAoa.iteration,
    })

    if (!upcomingPeriod) return false

    const isAfterCurrentPeriod =
      new Date(upcomingPeriod.start).getTime() >
      new Date(period.start).getTime()

    const hasStated = new Date(upcomingPeriod.start).getTime() < Date.now()

    return (
      isAfterCurrentPeriod &&
      hasStated &&
      upcomingAoa.status !== AoaApprovableStatus.Draft &&
      upcomingAoa.status !== AoaApprovableStatus.Withdrawn
    )
  })

  // not stated yet
  if (new Date(period.start).getTime() > Date.now()) return false

  // there are no upcoming active aoas
  return upcomingActiveAoas.length === 0
}

export const getIterationSelectOptions = ({
  cycles,
  endYear,
  startYear,
}: {
  cycles: Array<
    MinimalCycle & {
      created?: string
      iterationCycleId: string
    }
  >
  endYear: number
  startYear: number
}) => {
  const yearIterations: Array<
    IterationWithId & TimeRange & { legacy: boolean }
  > = []
  const quarterIterations: Array<
    IterationWithId & TimeRange & { legacy: boolean }
  > = []
  const monthlyIterations: Array<
    IterationWithId & TimeRange & { legacy: boolean }
  > = []

  for (let year = startYear; year <= endYear; year++) {
    let hasFinishedYear = false
    for (const cycle of cycles) {
      if (hasFinishedYear) continue

      const {
        monthStarts,
        quarters,
        year: yearIteration,
      } = getIterationsForYearWithCycleStart({
        cycleStart: cycle.cycleStart,
        earlyTerminationDate: cycle.earlyTerminationDate,
        terminated: cycle.terminated,
        year,
      })

      if (
        !cycle.terminated ||
        (cycle.earlyTerminationDate &&
          yearIteration?.end &&
          new Date(cycle.earlyTerminationDate).getTime() >
            new Date(yearIteration.end).getTime())
      )
        hasFinishedYear = true

      if (yearIteration) {
        yearIterations.push({
          end: yearIteration.end,
          iterationCycleId: cycle.iterationCycleId,
          iterationIntervalType: 'YEAR',
          iterationSequenceNumber: 0,
          legacy: Boolean(cycle.terminated),
          start: yearIteration.start,
          year,
        })
      }

      quarterIterations.push(
        ...quarters.map((quarter, index) => ({
          end: quarter.end,
          iterationCycleId: cycle.iterationCycleId,
          iterationIntervalType: 'QUARTER' as const,
          iterationSequenceNumber: index,
          legacy: Boolean(cycle.terminated),
          start: quarter.start,
          year,
        }))
      )
      monthlyIterations.push(
        ...monthStarts.map((month, index) => ({
          end: month.end,
          iterationCycleId: cycle.iterationCycleId,
          iterationIntervalType: 'MONTH' as const,
          iterationSequenceNumber: index,
          legacy: Boolean(cycle.terminated),
          start: month.start,
          year,
        }))
      )
    }
  }

  // /**
  //  * Should be sorted by start date, and then by interval type (year, quarter, month)
  //  */
  // const sorted = result.sort((iterationA, iterationB) => {
  //   const dateComparison = iterationA.start.localeCompare(iterationB.start)

  //   if (dateComparison !== 0) return dateComparison

  //   if (iterationA.iterationIntervalType === 'YEAR') return -1
  //   if (iterationB.iterationIntervalType === 'YEAR') return 1

  //   if (iterationA.iterationIntervalType === 'QUARTER') return -1
  //   if (iterationB.iterationIntervalType === 'QUARTER') return 1

  //   if (iterationA.iterationIntervalType === 'MONTH') return -1
  //   if (iterationB.iterationIntervalType === 'MONTH') return 1

  //   return 0
  // })

  return [...yearIterations, ...quarterIterations, ...monthlyIterations]
}

export const getIterationSelectOptionsFromPossibleIterations = ({
  iterationCycles,
  possibleIterations,
}: {
  iterationCycles: Array<
    MinimalCycle & {
      created?: string
      iterationCycleId: string
    }
  >
  possibleIterations: PossibleIteration[]
}) => {
  const result: Array<
    {
      iteration: ReturnType<typeof getIterationSelectOptions>[number]
    } & PossibleIteration
  > = []

  for (const possibleIteration of possibleIterations) {
    const cycle = iterationCycles.find(
      (findCycle) =>
        findCycle.iterationCycleId ===
        possibleIteration.iteration.iterationCycleId
    )
    if (!cycle) continue

    const period = getPeriodForIteration({
      cycle,
      iteration: possibleIteration.iteration,
    })

    if (!period) continue

    result.push({
      iteration: {
        ...possibleIteration.iteration,
        end: period.end,
        legacy: Boolean(cycle.terminated),
        start: period.start,
      },
      parentAoaId: possibleIteration.parentAoaId,
      unavailableReason: possibleIteration.unavailableReason,
    })
  }

  return result
}

export const sortByIteration = <T extends { iteration?: IterationWithId }>(
  items: T[],
  iterationCycles: Array<
    MinimalCycle & {
      created: string
      iterationCycleId: string
    }
  >
) => {
  // sort by start date, then by interval type (highest to lowest), then by sequence number
  // if there is no iteration assigned it should be first
  return items.sort((a, b) => {
    if (!a.iteration && !b.iteration) return 0
    if (!a.iteration) return -1
    if (!b.iteration) return 1

    const yearComparison = a.iteration.year - b.iteration.year

    if (yearComparison !== 0) return yearComparison

    const periodA = getPeriodForAoa({
      cycles: iterationCycles,
      iteration: a.iteration,
    })

    const periodB = getPeriodForAoa({
      cycles: iterationCycles,
      iteration: a.iteration,
    })

    // localeCompare period.start
    if (!periodA && !periodB) return 0
    if (!periodA) return -1
    if (!periodB) return 1

    const startComparison = periodA?.start.localeCompare(periodB?.start)

    if (startComparison !== 0) return startComparison

    const intervalOrder = ['YEAR', 'QUARTER', 'MONTH']

    return (
      intervalOrder.indexOf(a.iteration.iterationIntervalType) -
      intervalOrder.indexOf(b.iteration.iterationIntervalType)
    )
  })
}

/**
 * Check if the source iteration allows to fit the contained iteration
 * A yearly iteration may contain quarterly and monthly iterations
 * A quarterly iteration may contain monthly iterations
 */
export const checkIfIterationIsContained = ({
  sourceIteration,
  containedIteration,
  iterationCycles,
}: {
  containedIteration: Iteration & { iterationCycleId: string }
  iterationCycles: Array<
    MinimalCycle & {
      created?: string
      iterationCycleId: string
    }
  >
  sourceIteration: Iteration & { iterationCycleId: string }
}) => {
  const sourceIterationCycle = iterationCycles.find(
    (cycle) => cycle.iterationCycleId === sourceIteration.iterationCycleId
  )
  const containedIterationCycle = iterationCycles.find(
    (cycle) => cycle.iterationCycleId === containedIteration.iterationCycleId
  )

  if (!sourceIterationCycle || !containedIterationCycle) return false

  const sourceIterationPeriod = getPeriodForIteration({
    cycle: sourceIterationCycle,
    iteration: sourceIteration,
  })

  const containedIterationPeriod = getPeriodForIteration({
    cycle: containedIterationCycle,
    iteration: containedIteration,
  })

  if (!sourceIterationPeriod || !containedIterationPeriod) return false

  try {
    return (
      new Date(containedIterationPeriod.start).getTime() >=
        new Date(sourceIterationPeriod.start).getTime() &&
      new Date(containedIterationPeriod.end).getTime() <=
        new Date(sourceIterationPeriod.end).getTime()
    )
  } catch {
    return false
  }
}

export const checkIterationsEqual = (
  iterationA: IterationWithId,
  iterationB: IterationWithId
) => {
  return (
    iterationA.iterationCycleId === iterationB.iterationCycleId &&
    iterationA.iterationIntervalType === iterationB.iterationIntervalType &&
    iterationA.iterationSequenceNumber === iterationB.iterationSequenceNumber &&
    iterationA.year === iterationB.year
  )
}
