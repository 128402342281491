import { sortIndexRegex } from '@packages/aoa-utils'
import { AoaItemType, AoaProgressStatus, AoaSection } from '@packages/types'
import _ from 'lodash'
import { z } from 'zod'
import { isoDate, isoDatetime } from './common-shapes'

export const iterationIntervalType = z.enum(['YEAR', 'QUARTER', 'MONTH'])
export type IterationIntervalType = z.infer<typeof iterationIntervalType>

export const iterationCycle = z.object({
  cycleStart: z.object({
    day: z.number().gte(1).lte(31),
    month: z.number().gte(1).lte(12),
  }),
  earlyTerminationDate: z.string().datetime().optional(),
  iterationCycleId: z.string(),
  tenantId: z.string(),
  terminated: z.boolean().optional(),
})

export type IterationCycle = z.infer<typeof iterationCycle>

export const iterationShape = z.object({
  iterationCycleId: z.string().ulid(),
  iterationIntervalType,
  iterationSequenceNumber: z.number().gte(0).lte(11),
  year: z.number().gte(2_000).lte(2_100),
})

const aoaCommon = z
  .object({
    periodEnd: isoDate().optional(),
    periodStart: isoDate().optional(),
    revision: isoDatetime(),
  })
  .refine(
    (schema) =>
      schema.periodEnd === undefined ||
      schema.periodStart === undefined ||
      schema.periodEnd >= schema.periodStart,
    {
      message: 'Period end must be after period start',
      path: ['periodEnd'],
    }
  )

export const aoaSortable = z.object({
  sortIndex: z.string().regex(sortIndexRegex).optional(),
})

export const quantifierUnit = z.object({
  allowDecimals: z.boolean(),
  symbol: z.string(),
  symbolPosition: z.enum(['BEFORE', 'AFTER']),
})

export type QuantifierUnit = z.infer<typeof quantifierUnit>

export const aoaItem = z.intersection(
  aoaCommon,
  z.intersection(
    aoaSortable,
    z.object({
      itemId: z.string(),
      plannedQuantifier: z.number().optional(),
      plannedStartingQuantifier: z.number().optional(),
      quantifierUnit: quantifierUnit.optional(),
      responsibleUserId: z.string().optional().nullable(),
      tags: z
        .array(z.string().trim())
        .transform((value) => _.uniq(value))
        .optional(),
      text: z.string().trim().min(1).optional(),
      type: z.number().optional(),
    })
  )
)

export const aoaItemFilter = z.object({
  aoaIds: z.array(z.string()).optional(),
  aoaPrefilter: z
    .union([z.literal('all'), z.literal('active'), z.literal('expired')])
    .optional(),
  confidenceLevel: z
    .tuple([z.number().min(0).max(1), z.number().min(0).max(1)])
    .optional(),
  dueCheckins: z.boolean().optional(),
  intentLevelFilter: z.array(z.string()).optional(),
  itemType: z.array(z.nativeEnum(AoaItemType)).optional(),
  percentageComplete: z
    .tuple([z.number().min(0).max(100), z.number().min(0).max(100)])
    .optional(),
  responsibleUserId: z.string().optional(),
  status: z.array(z.nativeEnum(AoaProgressStatus)).optional(),
})

export const aoaItemCreate = z.object({
  aoaId: z.string(),
  itemType: z.nativeEnum(AoaItemType),
  parentItemId: z.string().optional(),
  parentItemIds: z.array(z.string()).default([]),
  periodEnd: isoDate().optional(),
  periodStart: isoDate().optional(),
  plannedQuantifier: z.number().optional(),
  plannedStartingQuantifier: z.number().optional(),
  quantifierUnit: quantifierUnit.optional(),
  responsibleUserId: z.string().optional().nullable(),
  section: z.nativeEnum(AoaSection),
  tags: z
    .array(z.string().trim())
    .transform((value) => _.uniq(value))
    .optional(),
  text: z.string().trim(),
  type: z.number().optional(),
})

export const aoaInfo = z.intersection(
  aoaCommon,
  z.object({
    accountableTeamId: z.string().ulid().optional(),
    // Deprecated
    accountableUserId: z.string().optional(),
    aoaId: z.string(),
    iteration: iterationShape.optional(),

    parentAoaIds: z.array(z.string()).optional(),

    // Deprecated
    periodEnd: z.string().optional(),
    // Deprecated
    periodStart: z.string().optional(),

    title: z.string().trim().optional(),
  })
)

export const checkin = z.object({
  actualConfidenceLevel: z.number().gte(0).lte(1),
  actualProgress: z.nativeEnum(AoaProgressStatus).optional(),
  actualPercentualProgress: z.number().gte(0).lte(1).optional(),
  actualQuantifier: z.number().optional(),
  comment: z.string().optional(),
  itemId: z.string(),
  revision: isoDatetime(),
})
