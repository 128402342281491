import type { GsPermission } from '@packages/permissions'
import { useCallback, useMemo } from 'react'
import { aoaApi } from './aoaApi'
import { useAuth } from './useAuth'
import { useTeamMemberships } from './useTeamMemberships'

const checkPermission = (
  currentPermissions: GsPermission[],
  requiredPermissions: GsPermission[]
) => {
  return requiredPermissions.every((permission) =>
    currentPermissions.includes(permission)
  )
}

/**
 * Checks if the current user has the required permissions
 * @param requiredPermissions - all the permissions the user needs to have
 */
export const usePermissions = (requiredPermissions: GsPermission[]) => {
  const { permissions } = useAuth()

  const authorized = useMemo(() => {
    return checkPermission(permissions, requiredPermissions)
  }, [permissions, requiredPermissions])

  const checkOtherPermission = useCallback(
    (otherRequiredPermissions: GsPermission[]) => {
      return checkPermission(permissions, otherRequiredPermissions)
    },
    [permissions]
  )

  return { authorized, checkOtherPermission }
}

/**
 * Checks if the current user is allowed to update the content of the aoa
 * @param aoaId - the aoaId to check permissions for
 * @returns {boolean} - true if the user has all the required permissions, false otherwise
 */
export const useAoaContentPermissions = (aoaId: string) => {
  const { authorized: authorizedReadAoa } = usePermissions(['Aoa:Read'])
  const { authorized: alwaysAuthorized } = usePermissions(['Aoa:Update'])
  const { authorized: contentWriteAuthorized } = usePermissions([
    'OwnedAoaContent:CRUD',
  ])

  const { userId } = useAuth()

  const { authorized: authorizedListTeams, memberships } =
    useTeamMemberships(undefined)

  const writePermissionMemberships = useMemo(() => {
    return memberships.filter(
      (membership) =>
        userId &&
        membership.memberStatus &&
        ['leader', 'editor'].includes(membership.memberStatus)
    )
  }, [memberships, userId])

  const aoaDetail = aoaApi.useGetAoaDetails(aoaId, {
    enabled: authorizedReadAoa && !alwaysAuthorized,
  })

  let authorized: boolean | null = null

  if (alwaysAuthorized) {
    authorized = true
  } else if (!authorizedListTeams) {
    authorized = false
  }

  if (
    contentWriteAuthorized &&
    aoaDetail.data?.accountableTeamId &&
    writePermissionMemberships.some(
      (membership) => membership.teamId === aoaDetail.data.accountableTeamId
    )
  ) {
    authorized = true
  }

  return { authorized }
}
