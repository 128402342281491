import openBaseModal, {
  type OpenBaseModalProps,
} from '@components/modals/openBaseModal'
import type { ModalTitleProps } from '@components/typography/ModalTitle'
import { Button, type ButtonProps, Divider } from '@mantine/core'
import { closeAllModals } from '@mantine/modals'
import { useI18nContext } from '@packages/i18n'
import type React from 'react'
import type { HTMLAttributes } from 'react'

// biome-ignore lint/suspicious/noExplicitAny:
export type UseConfirmOptions<TConfirm extends (...args: any[]) => any> = {
  cancelButtonLabel?: string
  children?:
    | React.ReactNode
    | ((props: { context: Parameters<TConfirm> }) => React.ReactNode)
  confirmButtonLabel?: string
  modalProps?: Omit<OpenBaseModalProps, 'title'> & {
    cancelProps?: ButtonProps & HTMLAttributes<HTMLButtonElement>
    confirmProps?: ButtonProps & HTMLAttributes<HTMLButtonElement>
  }
  onConfirm: TConfirm
  title?: Partial<ModalTitleProps>
}

// biome-ignore lint/suspicious/noExplicitAny: <explanation>
export const useConfirm = <TConfirm extends (...args: any[]) => void>({
  onConfirm,
  cancelButtonLabel,
  children,
  confirmButtonLabel,
  modalProps,
  title,
}: UseConfirmOptions<TConfirm>): ((...args: Parameters<TConfirm>) => void) => {
  const { LL } = useI18nContext()

  const { confirmProps, cancelProps, ...additionalModalProps } =
    modalProps ?? {}

  const openModal = (...args: Parameters<TConfirm>) =>
    openBaseModal({
      children: (
        <>
          {typeof children === 'function'
            ? children({ context: args })
            : children}

          <Divider className='-mx-6 my-4' />
          <div className='mt-md flex w-full justify-end space-x-4'>
            <Button
              onClick={() => closeAllModals()}
              variant='default'
              {...cancelProps}
            >
              {cancelButtonLabel ?? LL.buttons.cancel()}
            </Button>
            <Button
              onClick={() => {
                onConfirm(...args)
                closeAllModals()
              }}
              variant='filled'
              {...confirmProps}
            >
              {confirmButtonLabel ?? LL.buttons.confirm()}
            </Button>
          </div>
        </>
      ),
      ...additionalModalProps,
      title: {
        ...title,
        heading: title?.heading ?? LL.common.areYouSure(),
      },
    })

  return openModal
}
