import useRouteData from '@hooks/useRouteData'
import { ScrollArea } from '@mantine/core'
import { useLocalStorage } from '@mantine/hooks'
import { cn } from '@utils'
import { useEffect, useRef, useState } from 'react'
import { useLocation } from 'react-router-dom'
import Sidebar from './Sidebar'

type AppShellProps = {
  readonly children: React.ReactNode
}

const AppShell = ({ children }: AppShellProps) => {
  const viewport = useRef<HTMLDivElement>(null)

  const [isCollapsed, setCollapsed] = useLocalStorage({
    defaultValue: false,
    getInitialValueInEffect: false,
    key: 'sidebar-collapsed',
  })

  const { lastMatch } = useRouteData()

  const padding = lastMatch?.handle?.pageOptions?.padding ?? 'default'

  const [previousPathname, setPreviousPathname] = useState('')

  const { pathname } = useLocation()

  useEffect(() => {
    if (previousPathname && previousPathname !== pathname) {
      viewport?.current?.scrollTo({ behavior: 'instant', top: 0 })
    }

    setPreviousPathname(pathname)
  }, [pathname, previousPathname])

  return (
    <>
      <Sidebar isCollapsed={isCollapsed} setCollapsed={setCollapsed} />
      <ScrollArea
        className={cn(
          'h-[calc(100dvh_-_60px)] lg:h-screen',
          lastMatch?.handle?.pageOptions?.className
        )}
        viewportRef={viewport}
      >
        <div
          className={cn(
            'relative max-w-[100vw] transition-[padding-left] duration-300',
            {
              'lg:pl-sidebar-closed': isCollapsed,
              'lg:pl-sidebar-open': !isCollapsed,
            }
          )}
        >
          <div
            className={cn('space-y-8', {
              'px-5 pb-12 pt-6 lg:px-8': padding === 'default',
            })}
          >
            {children}
          </div>
        </div>
      </ScrollArea>
    </>
  )
}

export default AppShell
