import TenantListItem from '@components/auth/TenantListItem'
import { MenuButton } from '@components/core/ActionButtons'
import { useAuth } from '@hooks'
import { Button, Flex, Loader, Menu, Text } from '@mantine/core'
import { closeAllModals } from '@mantine/modals'
import { useI18nContext } from '@packages/i18n'
import { IconX } from '@tabler/icons-react'
import type { ApiReturnTypes } from '@types'
import { ReactTranslation } from '@utils'
import { trpc } from '@utils/trpc'
import { useCallback } from 'react'
import type { NavigateFunction } from 'react-router-dom'

export type AcceptInvitationModalProps = {
  readonly invitationResponse: ApiReturnTypes['account']['getUserAccount']['invitations'][0]
  readonly navigate: NavigateFunction
  readonly username: string
}

const AcceptInvitationModal = ({
  invitationResponse: { invitation, memberCount, planTitle, tenant },
  username,
  navigate,
}: AcceptInvitationModalProps) => {
  const { restoreSessions, switchUser } = useAuth<true>()
  const tenantId = tenant.tenantId

  const { LL } = useI18nContext()

  const {
    mutate: accept,
    isPending: isLoadingAccept,
    error: acceptError,
  } = trpc.account.acceptInvitation.useMutation({
    onSuccess: async () => {
      await restoreSessions({ forceRefresh: true }).then(async () => {
        await switchUser({ navigate, tenantId, username })
      })
      closeAllModals()
    },
  })

  const {
    mutate: dismiss,
    isPending: isLoadingDismiss,
    error: dismissError,
  } = trpc.account.dismissInvitation.useMutation({
    onSuccess: async () => {
      await restoreSessions({
        forceRefresh: true,
      })
      closeAllModals()
    },
  })

  const handleAccept = useCallback(async () => {
    accept({ tenantId })
  }, [accept, tenantId])

  const handleDismiss = useCallback(async () => {
    dismiss({ tenantId })
  }, [dismiss, tenantId])

  return (
    <div>
      <Text>
        {invitation.createdByDisplayName ? (
          <>
            <ReactTranslation
              message={LL.auth.invitationMessageBySomeone1({
                name: invitation.createdByDisplayName,
              })}
              renderComponent={(message) => <strong>{message}</strong>}
            />{' '}
            <ReactTranslation
              message={LL.auth.invitationMessageBySomeone2({
                org: tenant.displayName,
              })}
              renderComponent={(message) => <strong>{message}</strong>}
            />
          </>
        ) : (
          <ReactTranslation
            message={LL.auth.invitationMessage({
              org: tenant.displayName,
            })}
            renderComponent={(message) => <strong>{message}</strong>}
          />
        )}
        {invitation.roles.length > 1 && (
          <ReactTranslation
            message={LL.auth.invitationAsRoleSuffix({
              role: invitation.roles.join(', '),
            })}
            renderComponent={(message) => <strong>{message}</strong>}
          />
        )}
        .
      </Text>
      <div className='mt-6'>
        <div className='flex rounded-md bg-white outline outline-1 outline-gray-200'>
          <div className='pointer-events-none w-full grow'>
            <TenantListItem
              subText={
                <>
                  {[planTitle, LL.organization.memberCount(memberCount)]
                    .filter(Boolean)
                    .join('・')}
                </>
              }
              tenantInfo={tenant}
            />
          </div>
          <div className='my-auto mr-2.5'>
            <Menu position='bottom-end' shadow='md' width={200}>
              <Menu.Target>
                <MenuButton />
              </Menu.Target>
              <Menu.Dropdown>
                <Menu.Item
                  c='red'
                  disabled={isLoadingDismiss || isLoadingAccept}
                  leftSection={
                    isLoadingDismiss ? (
                      <Loader c='red' size={14} />
                    ) : (
                      <IconX size={14} />
                    )
                  }
                  onClick={handleDismiss}
                >
                  {LL.auth.declineInvitation()}
                </Menu.Item>
              </Menu.Dropdown>
            </Menu>
          </div>
        </div>
        <div className='mt-4 min-h-[50px]'>
          <Text c='red'>{acceptError?.message ?? dismissError?.message}</Text>
        </div>
      </div>
      <div>
        <Flex columnGap='md' justify='end'>
          <Button
            color='gray'
            onClick={() => closeAllModals()}
            variant='default'
          >
            {LL.buttons.close()}
          </Button>
          <Button
            disabled={isLoadingDismiss}
            loading={isLoadingAccept}
            onClick={handleAccept}
          >
            {LL.auth.acceptInvitation()}
          </Button>
        </Flex>
      </div>
    </div>
  )
}

export default AcceptInvitationModal
