import { useDebouncedValue } from '@mantine/hooks'
import { cn } from '@utils'
import type { ReactNode } from 'react'

type AnimatedExpandProps = {
  readonly children: ReactNode
  readonly className?: string
  readonly duration?: number
  readonly keepMounted?: boolean
  readonly open: boolean
}
/**
 * AnimatedExpand is a component that animates the height of its children when the `open` prop changes.
 *
 * Using a debounced value to allow the content to overflow (e.g. for shadows) after the animation has finished.
 */
const AnimatedExpand = ({
  children,
  open,
  className,
  duration = 300,
  keepMounted = true,
}: AnimatedExpandProps) => {
  const [debouncedOpen] = useDebouncedValue(open, duration)
  const [childrenMounted] = useDebouncedValue(open, duration * 2)

  return (
    <div
      className={cn(
        'grid transition-[grid-template-rows,_opacity] duration-300',
        'data-[state=open]:grid-rows-[1fr] data-[state=open]:opacity-100',
        'data-[state=closed]:grid-rows-[0fr] data-[state=closed]:opacity-0',
        className
      )}
      data-state={open ? 'open' : 'closed'}
    >
      <div
        className={cn({
          'overflow-hidden': !debouncedOpen || !open,
        })}
      >
        {open || debouncedOpen || childrenMounted || keepMounted
          ? children
          : null}
      </div>
    </div>
  )
}

export default AnimatedExpand
