import { useI18nContext } from '@packages/i18n'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useLocalizationSettings } from './useLocalizationSettings'

/**
 * @returns a function that reloads the current page or navigates to the given path
 */
export const useReload = () => {
  const navigate = useNavigate()

  const { currentLocale } = useLocalizationSettings()
  const { setLocale } = useI18nContext()

  useEffect(() => {
    setLocale(currentLocale)
  }, [currentLocale, setLocale])

  const reload = (to?: string) => {
    const target =
      !to || to.startsWith('/reload') ? window.location.pathname : to

    navigate(`/reload?location=${encodeURIComponent(target)}`, {
      replace: true,
    })
  }

  return reload
}
