import { useI18nContext } from '@packages/i18n'
import { IconLockExclamation } from '@tabler/icons-react'
import { useNavigate } from 'react-router-dom'
import EmptyState from './EmptyState'

type UnauthorizedStateProps = {
  readonly hideBackToStartButton?: boolean
}

const UnauthorizedState = (props: UnauthorizedStateProps) => {
  const { LL } = useI18nContext()
  const navigate = useNavigate()

  return (
    <div className='mx-auto my-20 max-w-md'>
      <EmptyState
        cta={
          props.hideBackToStartButton
            ? null
            : {
                label: LL.errorPages.unauthorized.button(),
                onClick: () =>
                  navigate('/', {
                    replace: true,
                  }),
              }
        }
        customIcon={IconLockExclamation}
        description={LL.errorPages.unauthorized.description()}
        headline={LL.errorPages.unauthorized.headline()}
      />
    </div>
  )
}

export default UnauthorizedState
