import { useLocalizationSettings } from '@hooks/useLocalizationSettings'
import { Loader } from '@mantine/core'
import { DatesProvider } from '@mantine/dates'
import { Notifications } from '@mantine/notifications'
import { TypesafeI18n, loadLocale } from '@packages/i18n'
import { TooltipProvider } from '@ui/Tooltip'
import AuthProvider from '@web/services/AuthProvider'
import BrandingProvider from '@web/services/BrandingProvider'
import { extend as dayjsExtend, locale as dayjsLocale } from 'dayjs'
import 'dayjs/locale/de'
import 'dayjs/locale/en'
import relativeTime from 'dayjs/plugin/relativeTime'
import utc from 'dayjs/plugin/utc'
import { Suspense, useEffect, useState } from 'react'
import { ReactFlowProvider } from 'reactflow'
import Router from './router/Router'
import { ApiProvider } from './services/ApiProvider'
import { TenantProvider } from './services/TenantProvider'

dayjsExtend(utc)
dayjsExtend(relativeTime)

const LocalizedContent = () => {
  const { currentLocale } = useLocalizationSettings()
  const [loaded, setLoaded] = useState(false)

  useEffect(() => {
    if (!loaded) loadLocale(currentLocale)
  })

  useEffect(() => {
    if (currentLocale) dayjsLocale(currentLocale)
  }, [currentLocale])

  useEffect(() => {
    loadLocale('en')
    loadLocale('de')
    setLoaded(true)
  }, [])

  if (!loaded) {
    return null
  }

  return (
    <TypesafeI18n locale={currentLocale}>
      <DatesProvider settings={{ locale: currentLocale }}>
        <TooltipProvider delayDuration={500} skipDelayDuration={250}>
          <ReactFlowProvider>
            <Router />
          </ReactFlowProvider>
        </TooltipProvider>
      </DatesProvider>
    </TypesafeI18n>
  )
}

const AppContent = () => {
  return (
    <BrandingProvider>
      <Suspense fallback={<Loader id='root.suspense' />}>
        <Notifications limit={4} position='top-right' zIndex={2_077} />
        <TenantProvider>
          <AuthProvider>
            <LocalizedContent />
          </AuthProvider>
        </TenantProvider>
      </Suspense>
    </BrandingProvider>
  )
}

const App = () => {
  return (
    <Suspense fallback={<div>critical error</div>}>
      <ApiProvider>
        <AppContent />
      </ApiProvider>
    </Suspense>
  )
}

export default App
