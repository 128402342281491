import type { BaseTranslation } from 'typesafe-i18n'

const en_auth = {
  auth: {
    acceptInvitation: 'Accept invitation',
    addAccount: 'Add another account',
    backToHome: 'Back to home',
    changePasswordButton: 'Change password',
    checkInbox: 'Check your inbox',
    checkInboxDescription:
      'We have sent you an email with a link to reset your password.',
    confirmAccount: 'Confirm your account',
    confirmationCodeHasBeenSentTo:
      'A confirmation code has been sent to <></>.',
    confirmPasswordLabel: 'Confirm password',
    confirmSignOutAll: 'Are you sure you want to sign out of all accounts?',
    createAccount: 'Create an account',
    createAccountSubtitle: "Let's get started! Please enter your details.",
    createOrganization: 'Create a new organization',
    declineInvitation: 'Decline invitation',
    didNotReceiveCode: 'Did not receive a code?',
    emailLabel: 'Email',
    enterpriseLogin: 'Enterprise login',
    errors: {
      aliasNotFound:
        'We could not find an organization with this identifier. Please try again.',
      CodeMismatchException:
        'Invalid code provided. It may have expired, please try again.',

      codeResendFailed: 'Failed to resend code',
      emailNotConfirmedError:
        'Unfortunately we cannot reset your password. Please contact support.',
      InvalidParameterException: 'Invalid values provided.',
      LimitExceededException: 'Too many attempts, please try again later.',
      NotAuthorizedException: {
        unauthorized: 'Incorrect username or password.',
        userAlreadyConfirmed: 'User is already confirmed, please sign in.',
      },
      PasswordResetRequiredException:
        'You must reset your password to continue.',
      passwordsDoNotMatch: 'Passwords do not match.',
      UsernameExistsException: 'An account with this email already exists.',
      UserNotFoundException: 'No account with this email exists.',
    },
    forgotPassword: 'Forgot password?',
    invalidInvitation: 'Invalid invitation',
    invalidInvitationDescription: 'The invitation is invalid or has expired.',
    invitationAsRoleSuffix: 'as <>{role:string}</>',
    invitationMessage: 'You have been invited to join {org:string}',
    invitationMessageBySomeone1: "You've been invited by <>{name:string}</>",
    invitationMessageBySomeone2: 'to join <>{org:string}}</>',
    invitationSignIn: 'Sign in to get started',
    invitationSignUp: 'Sign up to get started',
    invitationWelcomeText:
      'You have been invited to join the organization <>{0}</>.',
    invitedToOrgsList: 'You were invited to the following organizations:',
    loginUnavailable: 'Login is currently unavailable for this organization.',
    newPasswordRequired: 'New password required',
    noOrgs: 'No organizations found.',
    noUsersFound: 'No users found.',
    onboarding: {
      headline: 'Welcome to GrowthSquare',
      headlineDescription: 'We need some more information from you.',
    },
    passwordLabel: 'Password',
    pickNewPassword: 'Pick a new password',
    placeholders: {
      confirmPassword: 'Repeat your password',
      createPassword: 'Create a password',
      email: 'Enter your email',
      password: '••••••••',
    },
    resend: 'Resend',
    resendCooldownMessage: 'Resend in {0:number} second{{s}}',
    resetPassword: 'Reset password',
    resetPasswordDescription:
      'Enter your email address and we will send you a link to reset your password.',
    selectIteration: 'Select iteration…',
    selectOrganization: 'Select an Organization to continue',
    selectTeam: 'Select team…',
    selectUser: 'Select user…',
    sessionExpired: 'Your session has expired',
    sessionExpiredCta: 'Please sign in again.',
    signIn: 'Sign in',
    signInCta: 'Already have an account? <>Sign In</>',
    signInSubtitle: 'Welcome back! Please enter your details.',
    signInWithSso: 'Sign in with SSO',
    signOut: 'Sign out',
    signOutAll: 'Sign out of all accounts',
    signUp: 'Sign up',
    signUpCta: "Don't have an account? <>Sign up</>",
    switchOrganization: 'Switch organization',
    tenantAliasLabel: 'Your organization identifier',
    unknownUser: 'Unknown user',
    useSso: 'Use Single Sign-On (SSO)',
    validation: {
      invalidEmail: 'Invalid email address',
      passwordLength: 'Password should have at least 8 characters',
    },
  },
} as const satisfies BaseTranslation

export default en_auth
