import AuthWrapper from '@components/auth/AuthWrapper'
import LoginLayout from '@components/layout/LoginLayout'
import { LoadingOverlay } from '@mantine/core'
import type { TenantLoginInfo } from '@types'
import { isTRPCClientError, trpc } from '@utils/trpc'
import { useNavigate } from 'react-router-dom'

type LoginPageProps = {
  readonly forcedLoginInfo?: TenantLoginInfo
  readonly onComplete?: () => void
}

const LoginPage = ({ forcedLoginInfo, onComplete }: LoginPageProps) => {
  const navigate = useNavigate()

  const orgAlias = new URLSearchParams(window.location.search).get('org')

  const { data, isPending, error } = trpc[
    'public-router'
  ].getAuthInfoByTenantAlias.useQuery(orgAlias ?? '', {
    enabled: Boolean(orgAlias) && !forcedLoginInfo,
    retry: 1,
  })

  if (orgAlias && isPending)
    return <LoadingOverlay id='alias.loading' visible />

  // TODO: Handle other errors
  if (isTRPCClientError(error) && error.data?.code === 'NOT_FOUND') {
    navigate('/org-not-found')
    return null
  }

  return (
    <LoginLayout>
      <div className='pt-24'>
        <AuthWrapper
          forcedLoginInfo={forcedLoginInfo ?? data}
          // onComplete={() => navigate('/reload')}
          onComplete={onComplete ?? (() => {})}
        />
      </div>
    </LoginLayout>
  )
}

export default LoginPage
