import Breadcrumbs from '@components/layout/Breadcrumbs'
import useRouteData from '@hooks/useRouteData'
import { Text, Title } from '@mantine/core'
import { cn } from '@utils'
import type { ComponentProps } from 'react'

type PageHeaderProps = {
  readonly breadcrumbOverride?: ComponentProps<typeof Breadcrumbs>['override']
  readonly className?: string
  readonly headline?: string
  readonly headlineBadge?: React.ReactNode
  readonly lowerSection?: React.ReactNode
  readonly right?: React.ReactNode
  readonly subheader?: string
  readonly tabSection?: React.ReactNode
  readonly withoutBreadcrumbs?: boolean
  readonly withoutDivider?: boolean
  readonly withoutMargin?: boolean
}

const PageHeader = ({
  headline,
  right,
  subheader,
  withoutDivider,
  withoutMargin,
  withoutBreadcrumbs,
  lowerSection,
  breadcrumbOverride,
  className,
  tabSection,
  headlineBadge,
}: PageHeaderProps) => {
  const { pageOptions } = useRouteData()

  const applyNegativePadding =
    !pageOptions?.padding || pageOptions?.padding === 'default'

  return (
    <div
      className={cn(
        'bg-white',
        {
          '-mx-5 -mt-6 lg:-mx-8': applyNegativePadding,
          'border-0 border-b border-solid border-b-gray-200': !withoutDivider,
          'px-5 pt-6 lg:px-8':
            applyNegativePadding || pageOptions.padding === 'none',
        },
        className
      )}
    >
      <div className='max-w-page'>
        <div className='flex justify-between gap-x-2'>
          {!withoutBreadcrumbs && (
            <Breadcrumbs
              className={cn({
                'mb-2': headline,
                'mb-6': !headline,
              })}
              override={breadcrumbOverride}
            />
          )}
          {!headline && right ? right : null}
        </div>
        {headline && (
          <div className='space-y-2'>
            <div
              className={cn('justify-between md:flex', {
                'mb-1 pb-5': !withoutMargin && headline,
              })}
            >
              <div className='space-y-1'>
                <div className='flex gap-x-3'>
                  <Title
                    className='text-d-xs font-semibold text-gray-900'
                    order={1}
                  >
                    {headline}
                  </Title>
                  {headlineBadge ?? null}
                </div>
                {subheader && (
                  <Text c='gray.6' fw='400' size='sm'>
                    {subheader}
                  </Text>
                )}
              </div>
              {right && <div className='mt-3 md:mt-0'>{right}</div>}
            </div>
            {lowerSection && <div>{lowerSection}</div>}
          </div>
        )}
        {tabSection && <div>{tabSection}</div>}
      </div>
    </div>
  )
}

export default PageHeader
