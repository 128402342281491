const brandColor = '#fbbe1e'

const yellow = {
  25: '#FFFEFC',
  50: '#FFF8E4',
  100: '#FFF2CB',
  200: '#FFECB3',
  300: '#FFE69A',
  400: '#FFD969',
  500: '#FFCD38',
  600: '#FFC107',
  700: '#CF9D06',
  800: '#A07904',
  900: '#705503',
  950: '#584302',
}

const brand = {
  25: '#F5FCFF',
  50: '#DDF0F9',
  100: '#C4E5F3',
  200: '#ACD9ED',
  300: '#93CEE7',
  400: '#62B6DA',
  500: '#319FCE',
  600: '#0088C2',
  700: '#006D9B',
  800: '#005274',
  900: '#00364E',
  950: '#00293A',
}

const dark = {
  50: '#DDDDE0',
  100: '#C1C2C5',
  200: '#A6A7AB',
  300: '#909296',
  400: '#5C5F66',
  500: '#373A40',
  600: '#2C2E33',
  700: '#25262B',
  800: '#1A1B1E',
  900: '#141517',
  950: '#101113',
}

const black = {
  50: '#fafafa',
  100: '#C1C2C5',
  200: '#A6A7AB',
  300: '#909296',
  400: '#202020',
  500: '#101010',
  600: '#000',
  700: '#000',
  800: '#000',
  900: '#000',
  950: '#000',
}

const gray = {
  '25': '#FCFCFD',
  '50': '#F9FAFB',
  '100': '#F2F4F7',
  '200': '#EAECF0',
  '300': '#D0D5DD',
  '400': '#98A2B3',
  '500': '#667085',
  '600': '#475467',
  '700': '#344054',
  '800': '#182230',
  '900': '#101828',
  '950': '#0C111D',
}

const success = {
  '25': '#f6fef9',
  '50': '#ecfdf3',
  '100': '#dcfae6',
  '200': '#abefc6',
  '300': '#75e0a7',
  '400': '#47cd89',
  '500': '#17b26a',
  '600': '#079455',
  '700': '#067647',
  '800': '#085d3a',
  '900': '#074d31',
  '950': '#053321',
}

const error = {
  '25': '#fffbfa',
  '50': '#fef3f2',
  '100': '#fee4e2',
  '200': '#fecdca',
  '300': '#fda29b',
  '400': '#f97066',
  '500': '#f04438',
  '600': '#d92d20',
  '700': '#b42318',
  '800': '#912018',
  '900': '#7a271a',
  '950': '#55160c',
}

const warning = {
  '25': '#fffcf5',
  '50': '#fffaeb',
  '100': '#fef0c7',
  '200': '#fedf89',
  '300': '#fec84b',
  '400': '#fdb022',
  '500': '#f79009',
  '600': '#dc6803',
  '700': '#b54708',
  '800': '#93370d',
  '900': '#7a2e0e',
  '950': '#4e1d09',
}

const primary = brand
const accent = yellow

const palletes = {
  accent,
  black,
  dark,
  error,
  gray,
  primary,
  success,
  warning,
}

export { brandColor, palletes }
