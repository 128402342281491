import type { ReactElement } from 'react'

type LocalizedString = string

// create a component that handles the translated message

type WrapTranslationPropsType = {
  readonly message: LocalizedString
  readonly renderComponent: (messagePart: LocalizedString) => ReactElement
}

/**
 * Render a localized string with a section containing a custom component
 * @param param0 - the props
 * @param param0.message - the localized string to render
 * @param param0.renderComponent - the component to render
 * @example
 * ```tsx
 * <ReactTranslation
 *  message='foo <>bar</> quaz'
 *  renderComponent={(messagePart) => <strong>{messagePart}</strong>}
 * />
 * ```
 *
 * **Renders:**
 *
 * ```html
 * foo <strong>bar</strong> quaz
 * ```
 */
export const ReactTranslation = ({
  message,
  renderComponent,
}: WrapTranslationPropsType) => {
  // define a split character, in this case '<>'
  let [prefix, rest] = message.split('<>') as LocalizedString[]
  const remainingParts = rest?.split('</>') as LocalizedString[]

  let infix = remainingParts?.[0]
  const postfix = remainingParts?.[1]

  // render infix only if the message doesn't have any split characters
  if (!infix && !postfix) {
    infix = prefix
    prefix = '' as LocalizedString
  }

  return (
    <>
      {prefix}
      {renderComponent(infix as LocalizedString)}
      {postfix}
    </>
  )
}
