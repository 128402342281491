import {
  Group,
  LoadingOverlay,
  PinInput,
  Text,
  UnstyledButton,
} from '@mantine/core'
import { useInterval } from '@mantine/hooks'
import { useI18nContext } from '@packages/i18n'
import { translateCognitoError } from '@utils/error-utils'
import { confirmSignUp, resendSignUpCode } from 'aws-amplify/auth'
import { useCallback, useEffect, useState } from 'react'

type ConfirmEmailFormProps = {
  readonly onComplete: () => void
  readonly username: string
}

const CODE_LENGTH = 6

const ConfirmEmailForm = ({ username, onComplete }: ConfirmEmailFormProps) => {
  const [value, setValue] = useState('')
  const [errorMessage, setErrorMessage] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [resendCooldown, setResendCooldown] = useState(0)

  const { LL } = useI18nContext()

  const interval = useInterval(() => {
    if (resendCooldown > 0) setResendCooldown((previous) => previous - 1)
    else interval.stop()
  }, 1_000)

  useEffect(() => {
    interval.start()
    return interval.stop
  }, [interval])

  const handleResend = useCallback(async () => {
    if (resendCooldown > 0) return
    setIsLoading(true)
    try {
      await resendSignUpCode({
        username,
      })
    } catch (error) {
      setErrorMessage(
        `${LL.auth.errors.codeResendFailed()}, ${translateCognitoError(
          LL,
          error
        )}`
      )
    } finally {
      setResendCooldown(60)
      setIsLoading(false)
    }
  }, [LL, resendCooldown, username])

  const handleConfirmEmail = useCallback(
    async (code: string) => {
      if (isLoading) return

      setValue(code)
      if (code && errorMessage) setErrorMessage('')

      if (code.length === CODE_LENGTH) {
        setIsLoading(true)

        await confirmSignUp({
          confirmationCode: code,
          username,
        })
          .then(() => {
            onComplete()
          })
          .catch((error) => {
            if (error.code === 'CodeMismatchException') {
              setErrorMessage(LL.auth.errors.CodeMismatchException())
            } else if (error) {
              setErrorMessage(translateCognitoError(LL, error))
            }

            setIsLoading(false)
            setValue('')
          })
      }
    },
    [LL, errorMessage, isLoading, onComplete, username]
  )

  return (
    <Group justify='left'>
      <div className='relative mt-4'>
        <LoadingOverlay
          loaderProps={{
            size: 'md',
            variant: 'bars',
          }}
          transitionProps={{
            duration: 100,
          }}
          visible={isLoading}
        />
        <PinInput
          autoFocus
          className='transition-all'
          disabled={isLoading}
          length={CODE_LENGTH}
          name={isLoading ? 'code-loading' : 'code'}
          onChange={handleConfirmEmail}
          oneTimeCode
          size='lg'
          styles={{
            input: {
              fontWeight: 700,
            },
          }}
          type='number'
          value={value}
        />
        <Text c='red' className='min-h-[30px]' fw='400' mt='sm' size='md'>
          {errorMessage}
        </Text>
        <div>
          <Text c='dimmed' display='block' fw='400' mt='xl' size='sm'>
            {LL.auth.didNotReceiveCode()}{' '}
            <UnstyledButton
              className={resendCooldown > 0 ? '!cursor-not-allowed' : undefined}
              disabled={resendCooldown > 0}
              onClick={handleResend}
            >
              <Text
                c={resendCooldown > 0 ? 'gray.4' : 'primary'}
                fw={resendCooldown > 0 ? '400' : '700'}
              >
                {LL.auth.resend()}
              </Text>
            </UnstyledButton>
          </Text>
          {resendCooldown > 0 && (
            <Text c='gray.4' display='block' fw='400' size='sm'>
              {LL.auth.resendCooldownMessage(resendCooldown)}
            </Text>
          )}
        </div>
      </div>
    </Group>
  )
}

export default ConfirmEmailForm
