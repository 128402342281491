import type { SVGIcon } from '@icons'
import IconSearch from '@icons/search-sm.svg'
import { Button, Text } from '@mantine/core'
import type { TablerIcon } from '@types'
import { cn } from '@utils'
import type { ReactNode } from 'react'
import type { ButtonProps } from '../ActionButtons'
import IconRingWrapper from '../IconRingWrapper'

type EmptyStateProps = {
  readonly className?: string
  readonly cta?: {
    buttonProps?: Partial<ButtonProps> & { 'data-test'?: string }
    icon?: SVGIcon | TablerIcon
    label: string
    onClick: () => void
  } | null
  readonly customCta?: ReactNode
  readonly customIcon?: SVGIcon | TablerIcon
  readonly description: string
  readonly headline: string
  readonly secondary?: {
    icon?: SVGIcon | TablerIcon
    label: string
    onClick: () => void
  }
}
// https://www.figma.com/file/y4rGFth9izglnWZyyHmzbz/Empty-states-%E2%80%93-Untitled-UI-(untitledui.com)?type=design&node-id=5230-15964&mode=design&t=SpQBoKHTZmWyD0Z3-0

const EmptyState = ({
  description,
  headline,
  cta,
  customIcon,
  secondary,
  customCta,
  className,
}: EmptyStateProps) => {
  const Icon = customIcon ?? IconSearch

  return (
    <div
      className={cn(
        'flex animate-fade-in flex-col items-center space-y-4 text-center',
        className
      )}
    >
      <IconRingWrapper icon={Icon} ringBgClassName='scale-50' size='3rem' />
      <div className='z-10 max-w-xs'>
        <Text c='gray.9' fw='600'>
          {headline}
        </Text>
        <Text c='gray.6' fw='400'>
          {description}
        </Text>
      </div>
      {Boolean(secondary ?? cta ?? customCta) && (
        <div className='z-10 flex justify-around space-x-3'>
          {secondary && (
            <Button
              leftSection={
                secondary.icon ? (
                  <secondary.icon className='size-5' />
                ) : undefined
              }
              onClick={() => secondary.onClick()}
              variant='default'
            >
              {secondary.label}
            </Button>
          )}
          {cta && (
            <Button
              onClick={() => cta.onClick()}
              // biome-ignore lint/suspicious/noExplicitAny:
              {...(cta.buttonProps as any)}
              leftSection={
                cta.icon ? <cta.icon className='size-5' /> : undefined
              }
            >
              {cta.label}
            </Button>
          )}
          {customCta ?? null}
        </div>
      )}
    </div>
  )
}

export default EmptyState
