import { Tooltip } from '@mantine/core'
import { getResolvedDomains } from '@packages/constants'
import type { ReactNode } from 'react'

export type DevelopmentOnlyProps = {
  readonly children: ReactNode
  /**
   * @default ["ci-*", "dev", "ln", "ap"]
   */
  readonly stages?: string[]
  readonly withoutNotice?: boolean
}

const DevelopmentOnly = ({
  children,
  stages,
  withoutNotice,
}: DevelopmentOnlyProps) => {
  const currentStage = getResolvedDomains(window.location.hostname).stage

  const developmentStages = stages ?? ['ci-*', 'dev', 'ln', 'ap']

  const isDevelopment = developmentStages.some((stage) => {
    if (stage.endsWith('*')) {
      return currentStage.startsWith(stage.slice(0, -1))
    }

    return currentStage === stage
  })

  if (!isDevelopment) {
    return null
  }

  if (withoutNotice) {
    return <>{children}</>
  }

  return (
    <Tooltip
      label={`This is only shown in '${developmentStages.join(
        ', '
      )}' environment${developmentStages.length > 1 ? 's' : ''}`}
      openDelay={500}
      position='top-start'
    >
      <div>{children}</div>
    </Tooltip>
  )
}

export default DevelopmentOnly

export const withDevelopmentOnly = (
  enabled: boolean,
  component: ReactNode,
  props?: Omit<DevelopmentOnlyProps, 'children'>
) => {
  if (!enabled) {
    return component
  }

  return <DevelopmentOnly {...props}>{component}</DevelopmentOnly>
}
